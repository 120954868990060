import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "./impressum.module.css"


const ImpressumPage = ({ data }) => (
  <Layout>
    <SEO title="Impressum"/>
    <section id="content_1">
      <h1 className="headline">UNSER IMPRESSUM</h1>
      <div id="pic_1_index"/>
      <div id="pic_1_index_shadow"/>
    </section>

    <section id="content_2_schule" className={styles.content_2_schule}>
      <article id="con_2_rechts">

        <h3 className="small_sub"><span style={{ color: "#007c34" }}>IMPRESSUM</span></h3>

        <p className="text">Anbieter dieser Website ist die Grundschule Löhne-Bahnhof in Löhne</p>
        <br/>
        <h4 className="xsmall_sub">Schulleitung</h4>
        <p className="text">Frau Ulrike Lohöfener<br/>
          <span style={{ fontWeight: "bold" }}>Telefon:</span> 05732 3301</p>

        <br/>

        <h4 className="xsmall_sub">Inhaltlich verantwortlich f&uuml;r die Website</h4>
        <p className="text">(i.S.d. § 10 Abs. 3 MDStV)<br/>
          Frau Ulrike Lohöfener, Schulleiterin</p>
        <br/>
        <h4 className="xsmall_sub">Projektkoordination, Netzwerk</h4>
        <p className="text">Meike Klankwarth-Knoll // MzweiK Kommunikationsmanagement</p>
        <br/>
        <h4 className="xsmall_sub">Webdesign, Gestalterische Konzeption: </h4>
        <p className="text">André Hukriede // Mediengestaltung <a href="http://www.vektor-pixel.com"
                                                                  target="new"
                                                                  className="mehr"
                                                                  title="André Hukriede // Mediengestaltung">www.vektor-pixel.com</a>
        </p><br/>

        <h4 className="xsmall_sub">Datenschutzbeauftragter</h4>
        <p className="text"><a href="mailto:DSB-Schulen-HF@kreis-herford.de"
                               className="mehr">DSB-Schulen-HF@kreis-herford.de</a><br/></p>
        <br/>


        <h4 className="xsmall_sub">Rechtliche Hinweise</h4>
        <p className="text"><span
          style={{ fontWeight: "bold" }}>Urteil vom 12. Mai 1998 - 312 O 85/98 - Haftung f&uuml;r Links, Landgericht (LG) Hamburg:</span><br/>
          Wir betonen ausdr&uuml;cklich, dass wir keinen Einfluss auf die Gestaltung und die Inhalte externer
          Seiten haben. Deshalb distanzieren wir uns von den Inhalten der externen Seiten, auf die Links von
          unseren Seiten verweisen. Die Inhalte externer Seiten machen wir uns nicht zu Eigen. Die Links
          dienen lediglich zur Information von Besuchern unserer Seiten und beabsichtigen keine Wertung der
          entsprechenden Inhalte. Sollten sich auf unseren Seiten Links zu Seiten mit pornografischen,
          kinderfeindlichen, gewaltverherrlichenden, ausl&auml;nderfeindlichen oder diskriminierenden Inhalten
          befinden, so bitten wir Sie darum, uns umgehend dar&uuml;ber zu informieren, damit wir die
          betreffenden
          Links unverz&uuml;glich entfernen.</p>
        <br/>
        <h4 className="xsmall_sub">Haftungsausschluss</h4>
        <p className="text"><span style={{ fontWeight: "bold" }}>1. Inhalt des Onlineangebotes:</span>
          Der Autor &uuml;bernimmt keinerlei Gew&auml;hr f&uuml;r die Aktualit&auml;t, Korrektheit,
          Vollst&auml;ndigkeit oder Qualit&auml;t
          der bereitgestellten Informationen. Haftungsanspr&uuml;che gegen den Autor, welche sich auf
          Sch&auml;den
          materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen
          Informationen bzw. durch die Nutzung fehlerhafter und unvollst&auml;ndiger Informationen
          verursacht
          wurden, sind grunds&auml;tzlich ausgeschlossen, sofern seitens des Autors kein nachweislich
          vors&auml;tzliches
          oder grob fahrl&auml;ssiges Verschulden vorliegt. Alle Angebote sind freibleibend und
          unverbindlich. Der
          Autor beh&auml;lt es sich ausdr&uuml;cklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte
          Ank&uuml;ndigung zu ver&auml;ndern, zu erg&auml;nzen, zu löschen oder die Veröffentlichung
          zeitweise oder endg&uuml;ltig
          einzustellen.
          <br/><br/>
          <span style={{fontWeight: "bold"}}>2. Verweise und Links:</span>
          Bei direkten oder indirekten Verweisen auf fremde Webseiten ("Hyperlinks"), die au&szlig;erhalb
          des
          Verantwortungsbereiches des Autors liegen, w&uuml;rde eine Haftungsverpflichtung
          ausschlie&szlig;lich in dem
          Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch
          möglich
          und zumutbar w&auml;re, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Autor
          erkl&auml;rt
          hiermit ausdr&uuml;cklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu
          verlinkenden Seiten erkennbar waren. Auf die aktuelle und zuk&uuml;nftige Gestaltung, die
          Inhalte oder
          die Urheberschaft der verlinkten/verkn&uuml;pften Seiten hat der Autor keinerlei Einfluss.
          Deshalb
          distanziert er sich hiermit ausdr&uuml;cklich von allen Inhalten aller verlinkten
          /verkn&uuml;pften Seiten,
          die nach der Linksetzung ver&auml;ndert wurden. Diese Feststellung gilt f&uuml;r alle innerhalb
          des eigenen
          Internetangebotes gesetzten Links und Verweise sowie f&uuml;r Fremdeintr&auml;ge in vom Autor
          eingerichteten
          G&auml;steb&uuml;chern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen
          Formen von
          Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. F&uuml;r illegale,
          fehlerhafte oder
          unvollst&auml;ndige Inhalte und insbesondere f&uuml;r Sch&auml;den, die aus der Nutzung oder
          Nichtnutzung
          solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf
          welche
          verwiesen wurde, nicht derjenige, der &uuml;ber Links auf die jeweilige Veröffentlichung
          lediglich
          verweist.
          <br/><br/>
          <span style={{fontWeight: "bold"}}>3. Urheber- und Kennzeichenrecht:</span>
          Wir sind bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken,
          Tondokumente,
          Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente,
          Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen
          und
          Texte zur&uuml;ckzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch
          Dritte
          gesch&uuml;tzten Marken- und Warenzeichen unterliegen uneingeschr&auml;nkt den Bestimmungen
          des jeweils
          g&uuml;ltigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
          Eigent&uuml;mer. Allein
          aufgrund der blo&szlig;en Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht
          durch Rechte
          Dritter gesch&uuml;tzt sind! Das Copyright f&uuml;r veröffentlichte, vom Autor selbst
          erstellte Objekte bleibt
          allein beim Autor der Seiten. Eine Vervielf&auml;ltigung oder Verwendung solcher Grafiken,
          Tondokumente,
          Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne
          ausdr&uuml;ckliche Zustimmung des Autors nicht gestattet.
          <br/><br/>
          <span style={{fontWeight: "bold"}}>4. Datenschutz:</span>
          Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder
          gesch&auml;ftlicher
          Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten
          seitens des
          Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller
          angebotenen
          Dienste ist - soweit technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw.
          unter
          Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des
          Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften,
          Telefon-
          und Faxnummern sowie Emailadressen durch Dritte zur &uuml;bersendung von nicht ausdrücklich
          angeforderten
          Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten
          Spam-Mails bei Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten.
          <br/><br/>
          <span style={{fontWeight: "bold"}}>5. Rechtswirksamkeit dieses Haftungsausschlusses</span>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus
          auf diese
          Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der
          geltenden
          Rechtslage nicht, nicht mehr oder nicht vollst&auml;ndig entsprechen sollten, bleiben die
          übrigen Teile
          des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
        </p>


      </article>

    </section>
  </Layout>
)


export default ImpressumPage
